export const API_TOKEN = "token=TSSspCetReebXwr9";
export const REDIRECT_TO_BOOK = true;
export const COSY_PRICE_WEEKDAY = 90;
export const COSY_PRICE_WEEKEND = 100;
export const SUNSET_PRICE_WEEKDAY = 90;
export const SUNSET_PRICE_WEEKEND = 100;
// export const BASE_URL = "http://doesnotexit";

// Test mode
// export const BASE_URL = "http://localhost:8080";
// export const STRIPE_PUBLISHABLE_KEY = "pk_test_sIVtWZ9fTvVrBIUVAdJ7B22a";

// Live mode
export const BASE_URL = "https://api.cosyrooms.com.au";
export const STRIPE_PUBLISHABLE_KEY = "pk_live_cpfGbupqqVJVwYSv5Ez5D31p";
