import styled from "styled-components";
import {Button} from "../GlobalStyles";

export const ContainerCalendar = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: center;
  margin-left: 0;
  // See node_modules/react-calendar/dist/Calendar.css
  // for Calendar options

  // do not mark the current day
  .react-calendar__tile--now {
    background: rgba(0, 0, 0, 0);
  }

  .react-calendar {
    width: 100%;
  }

  @media (max-width: 768px) {
    display: inline;
    max-width: 100%;
    margin-left: 0;
  }
`

export const BoxCalendar = styled.div`
  background: #e6e6e6;
  padding: 5px;
  max-width: 100%;
  margin: 5px 5px 5px 0;
`;

export const BoxTitle = styled.div`
  font-weight: bold;
  padding-bottom: 5px;
`;

export const BookingContainer = styled.div`
  display: block;
  max-width: 100%;
  margin: 0;
`
export const BoxBooking = styled.div`
  background: #e6e6e6;
  max-width: 50%;
  padding: 5px 5px 5px 5px;
  margin: 5px auto;
  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
  }
`;
export const BoxBookingLoader = styled(BoxBooking)`
  display: grid;
  justify-content: center;
`
export const ContainerBookingButton = styled.div`
  max-width: 100%;
  text-align: center;
  padding: 5px 5px 5px 5px;
  margin: 5px auto;
`;
export const ErrorMessageDiv = styled.div`
  color: red;
  text-align: center;
`;
type ButtonProps = {
    background: string,
}
export const SelectableButton = styled.button<ButtonProps>`
  background: ${props => props.background};
  color: ${props => props.background === 'transparent' ? 'black' : 'white'}
`;
export const BoxBookingThankYou = styled.div`
  background: #e6e6e6;
  display: block;
  max-width: 50%;
  padding: 5px 5px 5px 5px;
  margin: 5px;
  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
  }
`;
export const CalendarDayBlockedStart = styled.div`
  height: 100%;
  display: flex;
  background: linear-gradient(to left top,
  #cacccd 50%,
  white 50%) no-repeat;);
`;
export const CalendarDaySelectedEndStartNextBooking = styled.div`
  height: 100%;
  display: flex;
  background: linear-gradient(to left top,
  #cacccd 50%,
  #00a699 50%) no-repeat;);
  color: white;
`;
export const CalendarDayContent = styled.div`
  margin: auto;
`;
export const StyledDatePickerRangeControllerWrapper = styled.div`
  .CalendarDay {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    // set padding to 0 to prevent a border on the half day view
    padding: 0;
  }
`;
export const PaymentLoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(219, 219, 219, 0.6);
`;
export const PaymentLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const TermsOverlay = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background: rgba(219, 219, 219, 0.95);
  z-index: 15;
  overflow-y: scroll;
`;
export const TermsCloseButton = styled(Button)`
  position: relative;
  margin: 20px;
  left: 50%;
  transform: translateX(-50%);
`;
export const A = styled.span`
  background-color: transparent;
  cursor: pointer;
  color: blue;
  text-decoration: underline;

  &:active {
    color: darkred;
  }

  &:hover {
    text-decoration: none;
  }
`;
