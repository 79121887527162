import React, {useState} from "react";
import {slide as Menu} from 'react-burger-menu'
import {Nav, NavLink, StyledBurgerMenu} from "./NavbarElements";

const Navbar = () => {
    // https://stackoverflow.com/questions/64438927/how-to-close-react-burger-menu-when-link-click-in-a-component
    const [isOpen, setOpen] = useState(false)
    const handleIsOpen = () => {
        setOpen(!isOpen)
    }
    const closeSideBar = () => {
        setOpen(false)
    }

    return (
        <Nav>
            <StyledBurgerMenu>
                <Menu isOpen={isOpen}
                      onOpen={handleIsOpen}
                      onClose={handleIsOpen}>
                    <NavLink onClick={closeSideBar} to={"/"}>Home</NavLink>
                    <NavLink onClick={closeSideBar} to={"/cosy"}>Cosyroom</NavLink>
                    <NavLink onClick={closeSideBar} to={"/sunset"}>Sunsetroom</NavLink>
                    <NavLink onClick={closeSideBar} to={"/contact"}>Contact</NavLink>
                    <NavLink onClick={closeSideBar} to={"/terms"}>Terms</NavLink>
                    <NavLink onClick={closeSideBar} to={"/privacy"}>Privacy Policy</NavLink>
                </Menu>
            </StyledBurgerMenu>
        </Nav>
    );
};

export default Navbar;
