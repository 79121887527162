import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Button, Content} from "../GlobalStyles";
import {Form, FormError, FormState, Input, Label, Textarea} from "./ContactStyles";
import ReactGA from "react-ga4";
import {fetchWithFetchException} from "../util/FetchWrapper";
import {FetchError} from "../util/FetchError";
import * as Sentry from "@sentry/react";

type FormData = {
    firstName: string;
    familyName: string;
    email: string;
    message: string;
};

enum FormSubmitState {
    NOT_SUBMIT, SUBMITTING, SUCCESS, FAILURE
}

const Contact = () => {
    const {register, handleSubmit, formState: {errors}} = useForm<FormData>();
    const [formSubmitState, setFormSubmitState] = useState<FormSubmitState>(FormSubmitState.NOT_SUBMIT);

    const onSubmit = handleSubmit(async data => {
        console.log(data);
        const requestOptions = {
            method: 'PUT', headers: {'Content-Type': 'text/plain'},
            body: JSON.stringify(data, null, 2)
        };
        setFormSubmitState(FormSubmitState.SUBMITTING);
        let fetchUrl = 'https://api.cosyrooms.com.au/message/?token=TSSspCetReebXwr9';
        try {
            const response: Response = await fetchWithFetchException(fetchUrl, requestOptions)
            if (response.ok) {
                ReactGA.event({
                    category: 'Form',
                    action: 'Submit'
                })
                setFormSubmitState(FormSubmitState.SUCCESS);
            } else {
                const fetchError = await FetchError.createFetchError("Form sumit failed", fetchUrl, response);
                ReactGA.event({
                    category: "Form",
                    action: "Form submit failed: " + fetchError.userMessage,
                    label: "Exception"
                });
                Sentry.captureException(fetchError);
            }
        } catch (error: any) {
            let err = error.cause ?? error;
            setFormSubmitState(FormSubmitState.FAILURE);
            ReactGA.event({
                category: "Form",
                action: "Form submit failed: " + err.message,
                label: "Exception"
            });
            Sentry.captureException(error);
        }
    });

    return (
        <Content>
            <h1>Contact</h1>
            <Form onSubmit={onSubmit}>
                <Label>
                    Given name:
                    <Input {...register("firstName", {required: true})} />
                    {errors.firstName && <FormError>This field is required</FormError>}
                </Label>
                <Label>
                    Family name:
                    <Input {...register("familyName", {required: true})} />
                    {errors.familyName && <FormError>This field is required</FormError>}
                </Label>
                <Label>
                    Email:
                    <Input {...register("email", {required: true})} />
                    {errors.email && <FormError>This field is required</FormError>}
                </Label>
                <Label>
                    Message:
                    <Textarea {...register("message", {required: true})} />
                    {errors.message && <FormError>This field is required</FormError>}
                </Label>
                {formSubmitState === FormSubmitState.SUBMITTING && (
                    <FormState>Sending..</FormState>
                )}
                {formSubmitState === FormSubmitState.SUCCESS && (
                    <FormState>Form submitted successfully.</FormState>
                )}
                {formSubmitState === FormSubmitState.FAILURE && (
                    <FormError>Form submit failed. Please try again later.</FormError>
                )}
                <Button type="submit">
                    Submit
                </Button>
            </Form>
        </Content>
    );
};

export default Contact;
