export class FetchException extends Error {
    constructor(input: RequestInfo, error: Error) {
        super("url: " + input + ", msg: " + error.message);
        this.cause = error;

        // Set the prototype explicitly.
        // It's worth mentioning that Object.setPrototypeOf needs to be called immediately after any super(...) calls.
        // https://stackoverflow.com/questions/31626231/custom-error-class-in-typescript
        Object.setPrototypeOf(this, FetchException.prototype);
    }
}
