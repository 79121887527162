import React from "react";
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga4";

class Props {
    to: string;
    gaAction: string;

    constructor(to: string, gaAction: string) {
        this.to = to;
        this.gaAction = gaAction;
    }
}

class NavLinkReactGA extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        ReactGA.event({
            category: "Link",
            action: this.props.gaAction
        })
    }

    render() {
        return (
            <NavLink onClick={this.onClick} to={this.props.to}>
                {this.props.children}
            </NavLink>
        );
    }
}

export default NavLinkReactGA;
