// https://stackoverflow.com/a/9198344
import styled from "styled-components";

export const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
`;

export const GalleryItem = styled.div`
  width: 33.33%;
  height: auto;
  padding: 2px;
  background-color: #dbedfc;
  margin: 5px;

  &:hover {
    padding: 1px;
    border: 1px solid #777;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const GalleryImg = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  padding: 15px;
  text-align: center;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  padding-top: 10px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-flow: row wrap;
  }
`;

export const DescriptionText = styled.div`
  width: 66.66%;
  height: auto;
  padding: 5px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
