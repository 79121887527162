import {Content} from "../GlobalStyles";
import {DescriptionContainer, DescriptionText} from "./RoomStyles";
import React from "react";
// the following terms of use are copied from Stripe Shop Terms of Use
// https://stripe.com/au/legal/stripe-shop

const Terms = () => {
    return (
        <Content>
            <h1>Cosyrooms Terms of Use</h1>
            <DescriptionContainer>
                <DescriptionText>
                    <p>Last updated: 25 March 2023</p>
                    <p>
                        Cosyrooms and/or its affiliates ("Cosyrooms", "we" or "us") operate an online store
                        ("Cosyrooms") located at www.Cosyrooms.com.au from which you may purchase products and services
                        ("Cosyrooms products").</p>
                    <p>
                        These Terms of Use apply to the content and functionality of Cosyrooms, and to Cosyrooms
                        products. These Terms of Use do not apply to products or services that we make available
                        pursuant to different terms, such as our payment processing services, which are governed by the
                        Cosyrooms Services Agreement.</p>
                    <h2>1. Card storage</h2>
                    <p>The card details are stored in order to collect the cancellation fee, if applicable, and to cover
                        damages according to the damage policy. The guest is free to pay with the stored card or by
                        other means upon arrival.</p>
                    <h2>2. Cancellation policy</h2>
                    <p>Full refund 5 days before arrival. After that, every night except the first will be refunded at
                        50%.</p>
                    <h2>3. Damage policy</h2>
                    <p>If anything is damaged, please report it to the host as soon as possible. Cosyrooms is free to
                        charge the replacement value to the stored card.</p>
                    <h2>4. Information that you provide to us</h2>
                    <p>
                        You may give us information about yourself when you visit Cosyrooms. Our Privacy Policy
                        explains our practices with respect to that information. We may need to send you email and text
                        messages in order to, for example, verify your identity or provide you with important
                        information. You authorize us to send those messages when you visit Cosyrooms and provide
                        your contact details. Standard text or data charges may apply to text messages. Where offered,
                        you may disable text message notifications by responding to any such message with "STOP", or by
                        following instructions provided in the message. However, by disabling text messaging, you may be
                        disabling important security controls and may increase the risk of loss to your business.</p>
                    <h2>5. Cosyrooms IP</h2>
                    <p>
                        As between you and Cosyrooms, Cosyrooms and its licensors exclusively own all rights, title, and
                        interest in the patents, copyrights (including rights in derivative works), moral rights, rights
                        of publicity, trademarks or service marks, logos and designs, trade secrets, and other
                        intellectual property embodied by, or contained in Cosyrooms (collectively, "Cosyrooms IP").
                        Cosyrooms IP is protected by copyright, trade secret, patent, and other intellectual property
                        laws, and all rights in Cosyrooms IP not expressly granted to you in these Terms of Use are
                        reserved.</p>
                    <p>
                        You may choose to or we may invite you to submit comments or ideas about improvements to the
                        Cosyrooms or our products or services ("Ideas"). If you submit an Idea to us, we will presume
                        that your submission was voluntary, unsolicited by us, and delivered to us without any
                        restrictions on our use of the Idea. You also agree that Cosyrooms has no fiduciary or any other
                        obligation to you in connection with any Idea you submit to us, and that we are free to use your
                        Ideas without any attribution or compensation to you.</p>
                    <h2>6. Access to Cosyrooms</h2>
                    <p>
                        Cosyrooms grants you a limited, revocable, non-exclusive, non-transferable license to access
                        Cosyrooms. This license does not include a right to use any of the content and information,
                        including product listings. Our Marks Usage Agreement sets out the terms and conditions that
                        apply to your use of our logos. Your permissions and/or licenses are automatically terminated by
                        any unauthorized use.</p>
                    <h2>7. Products, Content and Specifications</h2>
                    <p>
                        Details of the products and services available for purchase in Cosyrooms are set out in
                        Cosyrooms. All features, content, specifications, products and
                        prices of products and services described or depicted in this Cosyrooms are subject to change at
                        any time without notice. Unless expressly noted, all weights, measures and similar descriptions
                        are approximate and are provided for convenience purposes only. Packaging may vary from that
                        shown, and the appearance of a product in reality may differ from its appearance to you on
                        Cosyrooms due to the limitations of the systems that you use to access Cosyrooms. The
                        inclusion of any products or services in Cosyrooms at a particular time does not imply or
                        warrant that these products or services will be available at any time. Occasionally, the
                        manufacture or distribution of a certain product or service may be delayed for a number of
                        reasons. In such event, we will make reasonable efforts to notify you of the delay and keep you
                        informed of the revised delivery schedule. By placing an order, you represent that the products
                        ordered will be used only in a lawful manner.</p>
                    <h2>8. Subscriptions</h2>
                    <p>
                        a. Subscription terms. We may offer you the ability to purchase subscriptions via Cosyrooms.
                        Terms specific to a subscription will be disclosed to you at or prior to the time at which you
                        purchase the subscription, and by purchasing the subscription you are agreeing to those
                        terms.</p>
                    <p>
                        b. Free trials and promotional periods. If a subscription commences with a free trial or a
                        promotional period, you will have the right to cancel the subscription prior to the end of the
                        trial or period. If we do not provide you with an online cancellation mechanism, then you may
                        exercise this cancellation right by contacting us. We will email you prior to the end of the
                        free trial or promotional period to remind you that the trial or period is coming to an end, and
                        to give you an opportunity to cancel before the commencement of the paid period. If you do not
                        cancel, we will bill you at the end of the free trial or promotional period, and your subsequent
                        cancellation rights will be in accordance with the terms specific to the subscription.</p>
                    <p>
                        c. Cancellation. Your cancellation rights, and the mechanism via which you may notify us of your
                        decision to cancel, will be disclosed to you at or prior to the time at which you purchase a
                        subscription.</p>
                    <h2>9. Your account</h2>
                    <p>
                        We may require that you create an account to access Cosyrooms, including to make purchases.
                        If we do so, you must provide accurate information about yourself when you create an account and
                        ensure that you update us if that information changes. You must ensure that your login details
                        remain confidential. You are responsible for any activity, including any purchases made, under
                        your account. We reserve the right to terminate your account at any time and for any reason.</p>
                    <h2>10. Shipping and returns</h2>
                    <p>
                        This Section 7 applies to Cosyrooms products that are physical goods. For clarity, this Section
                        7 applies despite any contrary terms in any invoice or purchase order.</p>
                    <p>
                        Orders are shipped using carriers selected by Cosyrooms. The shipping fees you will be charged,
                        if any, will be provided to you before you confirm your order. If we provide you with an
                        estimated shipping date, the estimated delivery date is not guaranteed, and inventory shortages
                        or events beyond our control could impact the delivery date. Cosyrooms is not liable for
                        delivery later than the estimated delivery date, or for any loss, damage, or penalty you may
                        incur from a delay in shipment or delivery. Unless otherwise noted in the product description,
                        each Cosyrooms Product will be delivered FCA delivery location (as such location is designated
                        on the applicable order).</p>
                    <p>
                        Unless otherwise noted in the product description, Cosyrooms products may be returned in their
                        original packaging and condition (including all accessories and components provided) within 30
                        days of purchase. However, unless we tell you otherwise, you will only be entitled to a refund
                        if we provide you with an item that does not match the product description of the item that you
                        purchased. If that occurs, your exclusive remedy is to return the item in unused condition, in
                        exchange for a refund. To begin the return process, please contact us. Return shipping
                        instructions will be provided. Cosyrooms will cover the cost of return shipping and will refund
                        your purchase price in full.</p>
                    <h2>11. Sanctions and export policy</h2>
                    <p>
                        You may not use Cosyrooms or purchase any Cosyrooms Product in or for the benefit of a
                        country, organization, entity, or person embargoed or blocked by any government, including those
                        on sanctions lists identified by the United States Office of Foreign Asset Control (OFAC). We do
                        not claim, and we cannot guarantee that Cosyrooms or any Cosyrooms Product is or will be
                        appropriate or available for any location or jurisdiction, comply with the laws of any location
                        or jurisdiction, or comply with laws governing export, import, or foreign use.</p>
                    <h2>12. No warranties</h2>
                    <p>
                        We provide Cosyrooms, Cosyrooms IP and Cosyrooms products "as is" and "as available",
                        without any express, implied, or statutory warranties of title, merchantability, fitness for a
                        particular purpose, noninfringement, or any other type of condition, warranty or guarantee. No
                        data, documentation or any other information provided by Cosyrooms or obtained by you from or
                        through Cosyrooms - whether from Cosyrooms or another entity, and whether oral or written -
                        creates or implies any warranty from Cosyrooms to you.</p>
                    <p>
                        Cosyrooms disclaims any knowledge of, and does not guarantee: (a) the accuracy, reliability, or
                        correctness of any data provided through Cosyrooms; (b) that Cosyrooms products will
                        meet your specific needs or requirements; (c) that Cosyrooms will be available at any
                        particular time or location, or will function in an uninterrupted manner or be secure; (d) that
                        Cosyrooms will correct any defects or errors in Cosyrooms; or (e) that Cosyrooms is free
                        of viruses or other harmful code. Use of data, products or services that you access, purchase or
                        download through Cosyrooms is done at your own risk - you are solely responsible for any
                        damage to your property, loss of data, or any other loss that results from such access, purchase
                        or download.</p>
                    <p>
                        Nothing in these Terms of Use operates to exclude, restrict or modify the application of any
                        implied condition, warranty or guarantee, or the exercise of any right or remedy, or the
                        imposition of any liability under law to the extent that doing so would: (a) contravene that
                        law; or (b) cause any term of this agreement to be void.</p>
                    <h2>13. Limitation of liability</h2>
                    <p>
                        Under no circumstances will Cosyrooms be responsible or liable to you for any indirect,
                        punitive, incidental, special, consequential, or exemplary damages resulting from your use or
                        inability to use Cosyrooms or for the unavailability of Cosyrooms, or for lost profits,
                        personal injury, or property damage, or for any other damages arising out of, in connection
                        with, or relating to these Terms of Use or your use of Cosyrooms, even if such damages are
                        foreseeable, and whether or not you or Cosyrooms has been advised of the possibility of such
                        damages. Cosyrooms is not liable, and denies responsibility for, any damages, harm, or losses to
                        you arising from or relating to hacking, tampering, or other unauthorized access or use of the
                        Cosyrooms or your failure to use or implement anti-fraud measures, security controls, or any
                        other data security measure. Cosyrooms further denies responsibility for all liability and
                        damages to you or others caused by (a) your access or use of Cosyrooms inconsistent with our
                        instructions; (b) any unauthorized access of servers, infrastructure, or data used in connection
                        with Cosyrooms; (c) any bugs, viruses, or other harmful code that may be transmitted to or
                        through Cosyrooms; (d) any errors, inaccuracies, omissions, or losses in or to any data
                        provided to us; (e) third-party content provided by you; or (f) the defamatory, offensive, or
                        illegal conduct of others.</p>
                    <p>
                        You agree to limit any additional liability not disclaimed or denied by Cosyrooms in relation to
                        Cosyrooms, Cosyrooms IP, and Cosyrooms products, to your direct and documented damages; and
                        you further agree that under no circumstances will any such liability exceed in the aggregate
                        the greater of the amounts paid by you to Cosyrooms during the three-month period immediately
                        preceding the event that gave rise to your claim for damages, and USD $20.</p>
                    <p>
                        These limitations on our liability to you will apply regardless of the legal theory on which
                        your claim is based, including contract, tort (including negligence), strict liability, or any
                        other theory or basis.</p>
                    <h2>14. Disputes</h2>
                    <p>
                        a. Binding Arbitration: In the event that there is a dispute, claim or controversy arising out
                        of or relating to statutory or common law claims, the breach, termination, enforcement,
                        interpretation or validity of any provision of these Terms of Use, and the determination of the
                        scope or applicability of your agreement to arbitrate any dispute, claim or controversy
                        originating from these Terms of Use, but specifically excluding any dispute principally related
                        to either party's intellectual property (which such dispute will be resolved in litigation
                        before the court of New South Wales, Australia), will be
                        determined by arbitration in Sydney, Australia before a single arbitrator. The
                        arbitrator will apply the substantive law of the state of New South Wales, Australia, exclusive
                        of its conflict
                        or choice of law rules. Nothing in this paragraph will preclude the parties from seeking
                        provisional remedies in aid of arbitration from a court of appropriate jurisdiction. The parties
                        acknowledge that this Agreement evidences a transaction involving interstate commerce.</p>
                    <p>
                        b. Service of Process: Each party hereby irrevocably and unconditionally consents to service of
                        process through personal service at their corporate headquarters, registered address, or primary
                        address (for individuals or sole proprietors). Nothing in these Terms of Use will affect the
                        right of any party to serve process in any other manner permitted by law.</p>
                    <p>
                        c. Class Waiver: To the fullest extent permitted by law, each of the parties agrees that any
                        dispute arising out of or in connection with these Terms of Use, whether in arbitration or in
                        court, will be conducted only on an individual basis and not in a class, consolidated or
                        representative action. If for any reason a claim or dispute proceeds in court rather than
                        through arbitration, each party knowingly and irrevocably waives any right to trial by jury in
                        any action, proceeding or counterclaim arising out of or relating to these Terms of Use or any
                        of the transactions contemplated between the parties.</p>
                    <p>
                        d. Provision of an Award: Subject to the limitations of liability identified in these Terms of
                        Use, the appointed arbitrators may award monetary damages and any other remedies allowed by the
                        laws of the State of California. In making a determination, the arbitrator will not have the
                        authority to modify any term or provision of this Agreement. The arbitrator will deliver a
                        reasoned written decision with respect to the dispute (the "Award") to each party, who will
                        promptly act in accordance with the Award. Any Award (including interim or final remedies) may
                        be confirmed in or enforced by a state or federal court located in San Francisco, California.
                        The decision of the arbitrator will be final and binding on the parties, and will not be subject
                        to appeal or review.</p>
                    <p>
                        e. Fees: Each party will advance one-half of the fees and expenses of the arbitrators, the costs
                        of the attendance of the arbitration reporter at the arbitration hearing, and the costs of the
                        arbitration facility. In any arbitration arising out of or related to these Terms of Use, the
                        arbitrators will award to the prevailing party, if any, the costs and attorneys' fees reasonably
                        incurred by the prevailing party in connection with those aspects of its claims or defenses on
                        which it prevails, and any opposing awards of costs and legal fees awards will be offset.</p>
                    <p>
                        f. Confidentiality: The parties will maintain the confidential nature of the arbitration
                        proceeding, the hearing and the Award, except (i) as may be necessary to prepare for or conduct
                        the arbitration hearing on the merits, (ii) in connection with a court application as
                        contemplated above for a preliminary remedy, or confirmation of an Award or its enforcement,
                        (iii) our disclosure of the Award in confidential settlement negotiations, or (iv) as otherwise
                        required by applicable laws. The parties, witnesses, and arbitrator will treat as confidential
                        and will not disclose to any third person (other than witnesses or experts) any documentary or
                        other evidence produced in any arbitration hereunder, except as required by law or except if
                        such evidence was obtained from the public domain or was otherwise obtained independently from
                        the arbitration.</p>
                    <p>
                        g. Conflict of Rules: In the case of a conflict between the provisions of this Section 11 and
                        the rules governing arbitration identified in Section 11.a, the provisions of this Section 11
                        will prevail. If any provision of these Terms of Use to arbitrate is held invalid or
                        unenforceable, it will be so held to the minimum extent required by law and all the other
                        provisions will remain valid and enforceable.</p>
                    <h2>15. Applicable law</h2>
                    <p>
                        By using Cosyrooms, you agree that the laws of the state of New South Wales, Australia, without
                        regard
                        to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort
                        that might arise between you and Cosyrooms.</p>
                    <h2>16. Modification and severability</h2>
                    <p>
                        We have the right to change or add to the terms of these Terms of Use at any time, solely with
                        prospective effect, and to change, delete, discontinue, or impose conditions on use of
                        Cosyrooms by posting such changes on our website or any other website we maintain or own. You
                        can access a copy of the current version of these Terms of Use on our website at any time. You
                        can find out when these Terms of Use were last changed by checking the "Last updated" date at
                        the top of the page. </p>
                    <h2>17. Our address</h2>
                    <p>
                        Cosyrooms<br/>
                        56 Novara Crescent<br/>
                        Como NSW 2226<br/>
                        Australia<br/>
                        www.cosyrooms.com.au</p>
                </DescriptionText>
            </DescriptionContainer>
        </Content>
    )
};

export default Terms;
