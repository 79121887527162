import React from "react";
import 'react-dates/initialize';
import {DayPickerRangeController, FocusedInputShape} from 'react-dates';
import {default as moment} from 'moment'
import "react-dates/lib/css/_datepicker.css";
import {
    CalendarDayBlockedStart,
    CalendarDayContent,
    CalendarDaySelectedEndStartNextBooking,
    StyledDatePickerRangeControllerWrapper
} from "../../pages/BookStyles";
import {API_TOKEN, BASE_URL} from "../../Global";
import {Oval} from "react-loader-spinner";
import {FetchError} from "../../util/FetchError";
import {fetchWithFetchException} from "../../util/FetchWrapper";
import * as Sentry from "@sentry/react";

export enum Room {
    COSY = "COSY", SUNSET = "SUNSET", ALL = "ALL"
}

class CalendarProps {
    room: Room;
    onDatesChange?: (startDate: moment.Moment, endDate: moment.Moment, room: Room) => void;
    // reset startDate/endDate on every calendarVersion change
    calendarVersion?: number;
    readOnly?: boolean | undefined;
    useLoader?: boolean = false;
    loadError?: (error: Error) => void;

    constructor(room: Room, onDatesChange?: (startDate: moment.Moment, endDate: moment.Moment) => void) {
        this.room = room;
        this.onDatesChange = onDatesChange;
    }
}

class CalendarState {
    notAvailableDays: Array<Event> = []
    notAvailableDaysLoaded: boolean = false
    loadError: Error | undefined = undefined;

    startDate: moment.Moment | null = null
    endDate: moment.Moment | null = null
    focusedInput: FocusedInputShape | null = null
}

class Event {
    startDate: Date;
    endDate: Date;

    constructor(startDate: Date, endDate: Date) {
        this.startDate = startDate;
        this.endDate = endDate;
    }
}

class Calendar extends React.Component<CalendarProps, CalendarState> {
    controller = new AbortController();

    constructor(props: CalendarProps) {
        super(props);
        this.state = {
            notAvailableDays: [], notAvailableDaysLoaded: false,
            startDate: null, endDate: null,
            focusedInput: props.readOnly === true ? null : 'startDate',
            loadError: undefined
        };
        this.isDayBlocked = this.isDayBlocked.bind(this);
        this.isDayBooked.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
        this.renderDayContents = this.renderDayContents.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<CalendarProps>, prevState: Readonly<CalendarState>, snapshot?: any) {
        if (this.props.calendarVersion !== prevProps.calendarVersion) {
            this.setState({startDate: null, endDate: null});
        }
    }

    async componentDidMount() {
        try {
            let events: Array<Event>;
            switch (this.props.room) {
                case Room.COSY:
                    events = await this.loadEvents(
                        `${BASE_URL}/calendar/cosy/?${API_TOKEN}`
                    );
                    break;
                case Room.SUNSET:
                    events = await this.loadEvents(
                        `${BASE_URL}/calendar/sunset/?${API_TOKEN}`
                    );
                    break;
                case Room.ALL:
                default:
                    events = await this.loadEvents(
                        `${BASE_URL}/calendar/all/?${API_TOKEN}`
                    );
                    break;
            }

            this.setState({notAvailableDays: events, notAvailableDaysLoaded: true});
        } catch (error: any) {
            // logToServer("Calendar not loaded", error);
            if (// Chrome
                !error?.message?.endsWith("The user aborted a request.")
                // Firefox
                && !error?.message?.endsWith("The operation was aborted.")
                // Safari
                && !error?.message?.endsWith("Fetch is aborted")
            ) {
                Sentry.captureException(error);
            }
            this.props.loadError?.(error);
            this.setState({loadError: error})
        }
    }

    componentWillUnmount() {
        this.controller.abort();
    }

    isDayBlocked(day: moment.Moment): boolean {
        const {focusedInput} = this.state;
        const today: moment.Moment = moment();

        if (day.isBefore(today, 'day')) {
            // disable all passed dates
            return true;
        } else if (day.isSame(this.state.endDate) && focusedInput !== 'startDate') {
            // do not show endDate as blocked except when startDate for next try is to be selected.
            // According to the bookings it would be blocked because of check-in day for next booking.
            return false;
        } else if (
            // the user didn't select anything yet, assume startDate
            focusedInput == null
            // start date to be selected, also assume startDate if for whatever reason startDate is undefined
            || focusedInput === 'startDate' || this.state.startDate === undefined
            // allow all available days earlier than startDate as clicking on such a one defines startDate again
            || day.isBefore(this.state.startDate, 'day')) {
            // fix unlikely inconsistency
            if (this.state.startDate === undefined) {
                this.setState({focusedInput: 'startDate'})
            }

            return this.isDayBooked(day);
        } else {
            // endDate
            const foundEvent: Event | undefined = this.state.notAvailableDays.find((event: Event) => {
                return moment(event.startDate).isAfter(this.state.startDate, 'day');
            });
            if (foundEvent != null) {
                // do not allow all dates after next check in date to prevent the booking going over the next booking
                return day.isAfter(moment(foundEvent.startDate), 'day');
            } else {
                // no booking in the future, allow all dates after checkIn
                return false;
            }
        }
    }

    isDayBooked(day: moment.Moment): boolean {
        const foundEvents = this.state.notAvailableDays.filter((event: Event) => {
            return day.isSameOrAfter(moment(event.startDate), 'day')
                && day.isBefore(moment(event.endDate), 'day');
        });
        if (this.props.room === Room.ALL) {
            return foundEvents.length > 1;
        } else {
            return foundEvents.length > 0;
        }
    }

    renderDayContents(day: moment.Moment) {
        const {focusedInput} = this.state;
        const today: moment.Moment = moment();

        if (day.isSameOrBefore(today)) {
            // do not show any half days in the past or today
            return day.format('DD');
        }
        if (focusedInput === 'endDate') {
            // disable half days when endDate is to be selected.
            // The last day that can be selected is a half day.
            // We show it as normal day to prevent confusion.
            return day.format('DD');
        }

        if (day.isSame(this.state.endDate) && this.isDayBooked(day)) {
            // if the selected end date falls on the start date of next booking
            // we show it as half day
            return (
                <CalendarDaySelectedEndStartNextBooking>
                    <CalendarDayContent>{day.format('DD')}</CalendarDayContent>
                </CalendarDaySelectedEndStartNextBooking>);
        }

        if (this.isDayBooked(day)
            && !this.isDayBooked(moment(day).subtract('days', 1))
        ) {
            // previous day is not blocked so this day is the first blocked
            return (
                <CalendarDayBlockedStart>
                    <CalendarDayContent>{day.format('DD')}</CalendarDayContent>
                </CalendarDayBlockedStart>);
        }

        // all other cases blocked or free but no half days
        return day.format('DD');
    };

    onDatesChange(arg: { startDate: moment.Moment | null; endDate: moment.Moment | null }) {
        console.log("dates: " + arg.startDate + ", " + arg.endDate);
        if (arg.startDate !== null && !arg.startDate.isSame(this.state.startDate, 'day')) {
            // the user selects another date, clear end date
            this.setState({startDate: arg.startDate, endDate: null});
        } else {
            this.setState({startDate: arg.startDate, endDate: arg.endDate});
            if (arg.startDate !== null && arg.endDate !== null) {
                this.props.onDatesChange?.(arg.startDate, arg.endDate, this.props.room);
            }
        }
    }

    onFocusChange(focusedInput: FocusedInputShape | null) {
        if (this.props.readOnly === true) {
            return;
        }

        if (focusedInput == null) {
            this.setState({focusedInput: 'startDate'});
        } else {
            this.setState({focusedInput});
        }
    }

    render() {
        return (
            <StyledDatePickerRangeControllerWrapper>
                {this.state.notAvailableDaysLoaded && (
                    <DayPickerRangeController
                        renderDayContents={this.renderDayContents}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onDatesChange={this.onDatesChange}
                        focusedInput={this.state.focusedInput}
                        onFocusChange={this.onFocusChange}
                        initialVisibleMonth={null}
                        isDayBlocked={this.isDayBlocked}
                        enableOutsideDays={true}
                    />
                )}
                {this.state.loadError === undefined && this.props.useLoader && !this.state.notAvailableDaysLoaded && (
                    <Oval
                        height={200}
                        width={200}
                        color="#4fa94d"
                        wrapperStyle={{"padding": "50px"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={4}
                        strokeWidthSecondary={4}

                    />
                )}
            </StyledDatePickerRangeControllerWrapper>
        );
    };

    private async loadEvents(fetchUrl: RequestInfo): Promise<Array<Event>> {
        const requestOptions = {
            method: 'GET', headers: {'Content-Type': 'text/plain'},
            signal: this.controller.signal
        };
        const response: Response = await fetchWithFetchException(fetchUrl, requestOptions);
        if (response.ok) {
            const events: Event[] = await response.json();
            events.forEach((event: Event) => {
                event.startDate = new Date(event.startDate + 'T00:00:00');
                event.endDate = new Date(event.endDate + 'T00:00:00');
            });
            return events;
        } else {
            throw await FetchError.createFetchError("Could not load events", fetchUrl, response);
        }
    };
}

export default Calendar;
