import styled from "styled-components";

export const DescriptionContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-flow: row wrap;
  }
`;

export const DescriptionText = styled.div`
  width: 66.66%;
  height: auto;
  padding: 5px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const DescriptionCalendarRooms = styled.div`
  padding: 5px;
  max-width: 33.33%;
  margin-left: auto;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-left: 0;
  }
`;
export const DescriptionCalendarHome = styled.div`
  padding: 5px 5px 5px 0;
  max-width: 33.33%;
  margin-left: 0;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-left: 0;
  }
`;

// https://stackoverflow.com/a/9198344
export const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
`;

export const GalleryItem = styled.div`
  width: 33.33%;
  padding: 5px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const GalleryImg = styled.img`
  width: 100%;
  display: block; /* remove extra space below image */
`;

export const GalleryBreak = styled.div`
  flex-basis: 100%;
  height: 0;
`;
