import React from "react";
import {Button, Content} from "../GlobalStyles";
import {
    DescriptionCalendarRooms,
    DescriptionContainer,
    DescriptionText,
    GalleryContainer,
    GalleryImg,
    GalleryItem
} from "./RoomStyles";
import Calendar, {Room} from "../components/Calendar";
import NavLinkReactGA from "../components/NavLinkReactGA";
import {REDIRECT_TO_BOOK, SUNSET_PRICE_WEEKDAY, SUNSET_PRICE_WEEKEND} from "../Global";

const Sunset = () => {
    return (
        <Content>
            <h1>Sunsetroom in Como</h1>
            <DescriptionContainer>
                <DescriptionText>
                    <p>56 Novara Crescent, Como NSW 2226, Australia - <a
                        href="https://www.google.ch/maps/place/56+Novara+Cres,+Como+NSW+2226/">Show map</a></p>
                    <p>Take it easy at this unique and tranquil getaway, a 5 minutes walk from Como Station and a 30
                        minutes train ride from Sydney Central Station.</p>
                    <p>There are two guest rooms in our house with one shared bathroom. It's a cosy, old style
                        house.<br/>
                        The room includes a small fridge with freezer, a microwave, a kettle, tableware and cutlery.
                    </p>
                    <p>In winter, both beds are equipped with a fitted electric blanket.</p>
                    <p>
                        Aircon (cooling only) is $0.50/h.<br/>
                        Washier/dryer is $5 per cycle.
                    </p>
                    <h3>Price</h3>
                    <table>
                        <tbody>
                        <tr>
                            <td>Sunday - Thursday:</td>
                            <td>AUD {SUNSET_PRICE_WEEKDAY} per night, $10 for an additional person</td>
                        </tr>
                        <tr>
                            <td>Friday - Sunday:</td>
                            <td>AUD {SUNSET_PRICE_WEEKEND} per night, $10 for an additional person</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>
                        <br/>
                        <NavLinkReactGA to={REDIRECT_TO_BOOK ? "/book" : "/contact"}
                                        gaAction="Reserve Sunset"><Button>Reserve</Button></NavLinkReactGA>
                    </p>
                </DescriptionText>
                <DescriptionCalendarRooms>
                    <Calendar room={Room.SUNSET} readOnly={true}/>
                </DescriptionCalendarRooms>
            </DescriptionContainer>
            <GalleryContainer>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/sunset/PXL_20220910_055620929.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/sunset/PXL_20220910_051518956.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/sunset/PXL_20220910_051551839.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/sunset/PXL_20220910_065440540.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/sunset/PXL_20220910_065643319.MP.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/sunset/PXL_20220910_034838198.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/sunset/PXL_20211024_022541489.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/sunset/PXL_20210731_010418697.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/sunset/PXL_20211201_082846650.jpg')}/>
                </GalleryItem>
            </GalleryContainer>
        </Content>
    );
};

export default Sunset;
