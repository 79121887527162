import React, {useEffect} from 'react';
import './App.css';

import {Button, GlobalStyles} from "./GlobalStyles";
import Navbar from './components/Navbar';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import Home from './pages/Home';
import Cosy from "./pages/Cosy";
import Sunset from "./pages/Sunset";
import Contact from "./pages/Contact";

import ReactGA from 'react-ga4';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import SunsetCheckin from "./pages/SunsetCheckin";
import CosyCheckin from "./pages/CosyCheckin";
import BookThankYou from "./pages/BookThankYou";
import Terms from "./pages/Terms";
import Book from "./pages/Book";
import Privacy from "./pages/Privacy";

Sentry.init({
    dsn: "https://5af82dad582f4bcfbb4c20b7f988f307@o4504889844105216.ingest.sentry.io/4504889931464704",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    tunnel: "https://www.cosyrooms.com.au/sentry-tunnel/",
    // tunnel: "http://localhost:7878/tunnel/",
    release: "0.1.0",
    debug: true
});

const TRACKING_ID = "G-PBSYRS84L4";
ReactGA.initialize(TRACKING_ID);

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
};

function ErrorFallback(errorData: {
    error: Error;
    componentStack: string;
    eventId: string;
    resetError(): void;
}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{errorData.error.message}</pre>
            <Button onClick={errorData.resetError}>Try again</Button> <a href={"/contact"}><Button>Contact
            us</Button></a>
            <a href={"/home"}><Button>Home</Button></a>
        </div>
    )
}

function App() {
    usePageTracking();

    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
            <GlobalStyles/>
            <Navbar/>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/home' element={<Home/>}/>
                <Route path='/cosy' element={<Cosy/>}/>
                <Route path='/cosy/checkin' element={<CosyCheckin/>}/>
                <Route path='/sunset' element={<Sunset/>}/>
                <Route path='/sunset/checkin' element={<SunsetCheckin/>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/book' element={<Book/>}/>
                <Route path='/bookThankYou' element={<BookThankYou/>}/>
                <Route path='/terms' element={<Terms/>}/>
                <Route path='/privacy' element={<Privacy/>}/>
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Routes>
        </Sentry.ErrorBoundary>
    );
}

export default App;
