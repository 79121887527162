import React from "react";
import {Button, Content} from "../GlobalStyles";
import {
    DescriptionCalendarRooms,
    DescriptionContainer,
    DescriptionText,
    GalleryContainer,
    GalleryImg,
    GalleryItem
} from "./RoomStyles";
import Calendar, {Room} from "../components/Calendar";
import {NavLink} from "react-router-dom";
import NavLinkReactGA from "../components/NavLinkReactGA";
import {COSY_PRICE_WEEKDAY, COSY_PRICE_WEEKEND, REDIRECT_TO_BOOK} from "../Global";

const Cosy = () => {
    return (
        <Content>
            <h1>Cosyroom in Como</h1>
            <DescriptionContainer>
                <DescriptionText>
                    <p>56 Novara Crescent, Como NSW 2226, Australia - <a
                        href="https://www.google.ch/maps/place/56+Novara+Cres,+Como+NSW+2226/">Show map</a></p>
                    <p>Take it easy at this unique and tranquil getaway, a 5 minutes walk from Como Station and a 30
                        minutes train ride from Sydney Central Station.</p>
                    <p>There are two guest rooms in our house with one shared bathroom. It's a cosy, old style
                        house where the internal door can only be locked from the inside.</p>
                    <p>If you need a microwave, a TV or a lockable door from the outside, see our second room
                        called <NavLink to="/sunset">Sunsetroom in Como</NavLink>.</p>
                    <p>In winter, the bed is equipped with two fitted electric blankets.</p>
                    <p>Washier/dryer is $5 per cycle.</p>
                    <h3>Price</h3>
                    <table>
                        <tbody>
                        <tr>
                            <td>Sunday - Thursday:</td>
                            <td>AUD {COSY_PRICE_WEEKDAY} per night, $10 for an additional person</td>
                        </tr>
                        <tr>
                            <td>Friday - Sunday:</td>
                            <td>AUD {COSY_PRICE_WEEKEND} per night, $10 for an additional person</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>
                        <br/>
                        <NavLinkReactGA to={REDIRECT_TO_BOOK ? "/book" : "/contact"}
                                        gaAction="Reserve Sunset"><Button>Reserve</Button></NavLinkReactGA>
                    </p>
                </DescriptionText>
                <DescriptionCalendarRooms>
                    <Calendar room={Room.COSY} readOnly={true}/>
                </DescriptionCalendarRooms>
            </DescriptionContainer>
            <GalleryContainer>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/cosy/PXL_20220910_032023932.MP.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/cosy/PXL_20220910_031642785.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/cosy/PXL_20220910_032834277.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/cosy/PXL_20220910_034838198.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/cosy/PXL_20211024_022541489.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/cosy/PXL_20210731_010418697.jpg')}/>
                </GalleryItem>
                <GalleryItem>
                    <GalleryImg alt="Room" src={require('../images/cosy/PXL_20211201_082846650.jpg')}/>
                </GalleryItem>
            </GalleryContainer>
        </Content>
    );
};

export default Cosy;
