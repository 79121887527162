import React, {useEffect, useState} from 'react';
import {Content} from "../GlobalStyles";
import {BookingContainer, BoxBooking, BoxTitle, SelectableButton} from "./BookStyles";
import {Room} from "../components/Calendar";
import {default as moment} from "moment";
import * as Sentry from "@sentry/react";

const SELECTABLE_BUTTON_COLOR_NOT_SELECTED = 'transparent';
const SELECTABLE_BUTTON_COLOR_SELECTED = "#00a699";

export class BookingDetails {
    readonly checkIn: moment.Moment;
    readonly checkOut: moment.Moment;
    readonly room: Room;
    readonly numberOfGuests: number;
    readonly weekDays: number;
    readonly weekendDays: number;
    readonly weekDaysPrice: number;
    readonly weekendDaysPrice: number;
    readonly secondGuestPrice: number;
    readonly totalPrice: number;

    constructor(checkIn: moment.Moment, checkOut: moment.Moment, room: Room, numberOfGuests: number, weekDays: number,
                weekendDays: number, weekDaysPrice: number, weekendDaysPrice: number,
                secondGuestPrice: number, totalPrice: number) {
        this.checkIn = checkIn;
        this.checkOut = checkOut;
        this.room = room;
        this.numberOfGuests = numberOfGuests;
        this.weekDays = weekDays;
        this.weekendDays = weekendDays;
        this.weekDaysPrice = weekDaysPrice;
        this.weekendDaysPrice = weekendDaysPrice;
        this.secondGuestPrice = secondGuestPrice;
        this.totalPrice = totalPrice;
    }
}

export class Props {
    bookingDetails: BookingDetails;
    onNumberOfGuestsChanged: (numberOfGuests: number) => void;
    readOnly: boolean;

    constructor(bookingDetails: BookingDetails,
                onNumberOfGuestsChanged: (numberOfGuests: number) => void,
                readOnly: boolean) {
        this.bookingDetails = bookingDetails;
        this.onNumberOfGuestsChanged = onNumberOfGuestsChanged;
        this.readOnly = readOnly;
    }
}

export const formatPrice = (price: number | undefined): string => {
    if (price !== undefined && price > 0) {
        const formattedPrice = price / 100.0;
        return `$${formattedPrice}`;
    } else {
        Sentry.captureMessage("formatPrice(): Wrong price: " + price);
        return "";
    }
}

export const formatRoom = (room: Room | undefined): string => {
    switch (room) {
        case Room.COSY:
            return "Cosyroom";
        case Room.SUNSET:
            return "Sunsetroom";
        default:
            return "-";
    }
}

const BookingDetailsBox = (props: Props) => {
    const [oneGuestBackground, setOneGuestBackground] = useState<string>(SELECTABLE_BUTTON_COLOR_SELECTED);
    const [twoGuestsBackground, setTwoGuestsBackground] = useState<string>(SELECTABLE_BUTTON_COLOR_NOT_SELECTED);

    useEffect(() => {
        updateNumberOfGuestsButtons(props.bookingDetails?.numberOfGuests)
    });

    const updateNumberOfGuestsButtons = (numberOfGuests: number) => {
        switch (numberOfGuests) {
            case 1:
                setOneGuestBackground(SELECTABLE_BUTTON_COLOR_SELECTED);
                setTwoGuestsBackground(SELECTABLE_BUTTON_COLOR_NOT_SELECTED);
                break;
            case 2:
                setOneGuestBackground(SELECTABLE_BUTTON_COLOR_NOT_SELECTED);
                setTwoGuestsBackground(SELECTABLE_BUTTON_COLOR_SELECTED);
                break;
        }
    }

    const onNumberOfGuestsClick = (numberOfGuests: number) => {
        if (numberOfGuests < 0 || numberOfGuests > 2) {
            Sentry.captureMessage("onNumberOfGuestsClick(): Wrong numberOfGuests: " + numberOfGuests);
            return;
        }
        updateNumberOfGuestsButtons(numberOfGuests);
        props.onNumberOfGuestsChanged(numberOfGuests);
    }

    const addPluralS = (value: number | undefined): string => {
        if (value === undefined || value === 1) {
            return "";
        } else {
            return "s";
        }
    }

    return (
        <Content>
            <BookingContainer>
                {props.bookingDetails && (
                    <BoxBooking>
                        <BoxTitle>Reservation</BoxTitle>
                        <table>
                            <tbody>
                            <tr>
                                <td>Number of guests:</td>
                                {!props.readOnly && (
                                    <td>
                                        <SelectableButton background={oneGuestBackground}
                                                          onClick={() => onNumberOfGuestsClick(1)}>One</SelectableButton>
                                        <SelectableButton background={twoGuestsBackground}
                                                          onClick={() => onNumberOfGuestsClick(2)}>Two</SelectableButton>
                                    </td>
                                )}
                                {props.readOnly && (
                                    <td>{props.bookingDetails.numberOfGuests}</td>
                                )}
                            </tr>
                            <tr>
                                <td>Room:</td>
                                <td>{formatRoom(props.bookingDetails?.room)}</td>
                            </tr>
                            <tr>
                                <td>Check in:</td>
                                <td>{props.bookingDetails?.checkIn?.format("LL") ?? ""}</td>
                            </tr>
                            <tr>
                                <td>Check out:</td>
                                <td>{props.bookingDetails?.checkOut?.format("LL") ?? ""}</td>
                            </tr>
                            {props.bookingDetails.weekDays !== 0 && (
                                <tr>
                                    <td>{props.bookingDetails?.weekDays} week
                                        day{addPluralS(props.bookingDetails?.weekDays)}:
                                    </td>
                                    <td>{formatPrice(props.bookingDetails?.weekDaysPrice)}</td>
                                </tr>
                            )}
                            {props.bookingDetails.weekendDays !== 0 && (
                                <tr>
                                    <td>{props.bookingDetails?.weekendDays} weekend
                                        day{addPluralS(props.bookingDetails?.weekendDays)}:
                                    </td>
                                    <td>{formatPrice(props.bookingDetails?.weekendDaysPrice)}</td>
                                </tr>
                            )}
                            {props.bookingDetails.numberOfGuests > 1 && (
                                <tr>
                                    <td>Second Guest:</td>
                                    <td>{formatPrice(props.bookingDetails?.secondGuestPrice)}</td>
                                </tr>
                            )}
                            <tr>
                                <td>Total:</td>
                                <td>{formatPrice(props.bookingDetails?.totalPrice)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </BoxBooking>
                )}
            </BookingContainer>
        </Content>
    );
};

export default BookingDetailsBox;
