import React from "react";
import {NavLink} from "react-router-dom";
import {Content} from "../GlobalStyles";
import {
    Description,
    DescriptionContainer,
    DescriptionText,
    GalleryContainer,
    GalleryImg,
    GalleryItem
} from "./HomeStyles";
import {DescriptionCalendarHome} from "./RoomStyles";
import Calendar, {Room} from "../components/Calendar";
import {COSY_PRICE_WEEKDAY, SUNSET_PRICE_WEEKDAY} from "../Global";

const Home = () => {
    return (
        <Content>
            <h1>Cosyrooms in Como</h1>
            <GalleryContainer>
                <GalleryItem>
                    <NavLink to="/cosy">
                        <GalleryImg alt="Cosyroom" src={require('../images/cosy/PXL_20220910_032023932.MP.jpg')}/>
                    </NavLink>
                    <Description>Cosyroom:<br/>
                        From AUD {COSY_PRICE_WEEKDAY} per night
                    </Description>
                </GalleryItem>
                <GalleryItem>
                    <NavLink to="/sunset">
                        <GalleryImg alt="Sunsetroom" src={require('../images/sunset/PXL_20220910_055620929.jpg')}/>
                    </NavLink>
                    <Description>Sunsetroom:<br/>
                        From AUD {SUNSET_PRICE_WEEKDAY} per night
                    </Description>
                </GalleryItem>
            </GalleryContainer>
            <DescriptionContainer>
                <DescriptionText>
                    <p>Como is a safe area any time of the day.
                        The Georges River marina with coffee shops, park, a swimming and a pool area is a 15 minutes
                        walk and the famous Hotel/Pub The Como is 10 minutes away.
                        To Jannali it's also about 15 minutes where you find lots of shops, coffee shops and restaurants
                        including Woolies.</p>
                    <h3>Getting around</h3>
                    <p>The place is a 5 minutes walk from Como Station and from there the train ride to Sydney Central
                        Station is about 30 minutes. On street parking is available in front of the property and it can
                        easily be reached by car. The train ride from Como Station to the famous Cronulla beach is 22
                        minutes.</p>
                    <h3>Availability</h3>
                    <DescriptionCalendarHome>
                        <Calendar room={Room.ALL} readOnly={true}/>
                    </DescriptionCalendarHome>
                </DescriptionText>
            </DescriptionContainer>
        </Content>
    );
};

export default Home;
