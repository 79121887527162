import styled from "styled-components";

export const Checkin = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    // for desktop
    width: 50%;
  }
`;

export const CheckinImg = styled.img`
  width: 100%;
`;

export const CheckinPortraitImg = styled.img`
  width: 50%;
`;
