import React, {useState} from 'react';
import * as stripeJs from '@stripe/stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import uuid from 'react-uuid';
import {Appearance} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {Content, ErrorMessage} from "../GlobalStyles";
import {
    BookingContainer,
    BoxBookingLoader,
    BoxCalendar,
    BoxTitle,
    ContainerCalendar,
    PaymentLoader,
    PaymentLoaderContainer,
    TermsCloseButton,
    TermsOverlay
} from "./BookStyles";
import Calendar, {Room} from "../components/Calendar";
import PaymentForm from "./PaymentForm";
import {Elements} from "@stripe/react-stripe-js";
import {default as moment} from "moment";
import GuestForm, {Guest} from "./GuestForm";
import BookingDetailsBox, {BookingDetails, formatPrice, formatRoom} from "./BookingDetailsBox";
import {API_TOKEN, BASE_URL, STRIPE_PUBLISHABLE_KEY} from "../Global";
import {Oval} from "react-loader-spinner";
import Terms from "./Terms";
import OutsideClickHandler from 'react-outside-click-handler';
import {fetchWithFetchException} from "../util/FetchWrapper";
import {FetchError} from "../util/FetchError";
import {messageFromError, messageFromFetchError} from "../util/ResponseError";
import * as Sentry from "@sentry/react";

class Booking {
    readonly room: Room;
    readonly checkIn: string;
    readonly checkOut: string;
    readonly numberOfGuests: Number;

    constructor(room: Room, checkIn: string, checkOut: string, numberOfGuests: Number) {
        this.room = room;
        this.checkIn = checkIn;
        this.checkOut = checkOut;
        this.numberOfGuests = numberOfGuests;
    }
}

class BookingRequest {
    readonly idempotencyKey: string;
    readonly booking: Booking;

    constructor(idempotencyKey: string, booking: Booking) {
        this.idempotencyKey = idempotencyKey;
        this.booking = booking;
    }
}

class PaymentRequest {
    readonly idempotencyKey: string;
    readonly booking: Booking;
    readonly guest?: Guest;

    constructor(idempotencyKey: string, booking: Booking, guest: Guest | undefined = undefined) {
        this.idempotencyKey = idempotencyKey;
        this.booking = booking;
        this.guest = guest;
    }
}

class PaymentResponse {
    readonly bookingDetails: BookingDetails;
    readonly setupIntentClientSecret: string;
    readonly setupIntentId: string;

    constructor(bookingDetails: BookingDetails, setupIntentClientSecret: string, setupIntentId: string) {
        this.bookingDetails = bookingDetails;
        this.setupIntentClientSecret = setupIntentClientSecret;
        this.setupIntentId = setupIntentId;
    }
}

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const Book = () => {
    const [setupIntentClientSecret, setSetupIntentClientSecret] = useState<string | undefined>(undefined);
    const [bookingLoading, setBookingLoading] = useState<boolean>(false);
    const [bookingDetails, setBookingDetails] = useState<BookingDetails | undefined>(undefined);
    const [returnUrl, setReturnUrl] = useState<string | undefined>(undefined);
    const [guest, setGuest] = useState<Guest | undefined>(undefined);
    const [cosyCalendarVersion, setCosyCalendarVersion] = useState<number>(0);
    const [sunsetCalendarVersion, setSunsetCalendarVersion] = useState<number>(0);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState<boolean>(false);
    const [isTermsShowing, setIsTermShowing] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<React.ReactElement<string> | undefined>(undefined);

    const createBooking = async (checkIn: moment.Moment | undefined, checkOut: moment.Moment | undefined,
                                 guest: Guest | undefined, room: Room | undefined,
                                 numberOfGuests: number | undefined) => {
        setBookingLoading(true);
        let fetchUrl = `${BASE_URL}/checkout/booking/?${API_TOKEN}`;
        console.log("checkIn: " + checkIn + ", checkOut: " + checkOut);
        if (checkIn !== undefined && checkOut !== undefined && room !== undefined) {
            console.log("fetchUrl: " + fetchUrl);
            let booking = new BookingRequest(
                uuid(),
                new Booking(
                    room,
                    toIsoString(checkIn),
                    toIsoString(checkOut),
                    numberOfGuests ?? 2
                )
            );
            console.log("booking: " + JSON.stringify(booking));
            setErrorMessage(undefined);
            const response: Response = await fetchWithFetchException(fetchUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(booking)
                }
            );
            if (response.ok) {
                const bookingDetailsUnmodified: BookingDetails = await response.json();

                // copy the object with the spread operator and modify checkIn/checkOut
                // https://daily-dev-tips.com/posts/javascript-overwrite-property-in-an-object/
                const bookingDetails = {
                    ...bookingDetailsUnmodified,
                    // convert date fields to moment
                    checkIn: moment(bookingDetailsUnmodified.checkIn),
                    checkOut: moment(bookingDetailsUnmodified.checkOut)
                };
                setBookingLoading(false);
                setBookingDetails(bookingDetails);
                setReturnUrl(generateReturnUrl(bookingDetails));
            } else {
                const fetchError = await FetchError.createFetchError("data not set "
                    + "checkIn: " + checkIn + "checkOut: " + checkOut + "room: " + room,
                    fetchUrl, response);
                Sentry.captureException(fetchError);
                setErrorMessage(messageFromFetchError(fetchError));
            }
        } else {
            Sentry.captureMessage("data not set "
                + "checkIn: " + checkIn + "checkOut: " + checkOut + "room: " + room);
        }

        setBookingLoading(false);
    }
    const requestPayment = async (guest: Guest) => {
        let fetchUrl = `${BASE_URL}/checkout/setupIntent/?${API_TOKEN}`;
        console.log("checkIn: " + bookingDetails?.checkIn + ", checkOut: " + bookingDetails?.checkOut);
        if (bookingDetails?.checkIn !== undefined && bookingDetails?.checkOut !== undefined
            && bookingDetails?.room !== undefined) {
            console.log("fetchUrl: " + fetchUrl);
            let paymentRequest = new PaymentRequest(
                uuid(),
                new Booking(
                    bookingDetails.room,
                    toIsoString(bookingDetails.checkIn),
                    toIsoString(bookingDetails.checkOut),
                    bookingDetails.numberOfGuests
                ),
                guest
            );
            console.log("paymentRequest: " + JSON.stringify(paymentRequest));
            setErrorMessage(undefined);
            const response: Response = await fetchWithFetchException(fetchUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(paymentRequest)
                }
            )
            if (response.ok) {
                const paymentResponse: PaymentResponse = await response.json();

                // copy the object with the spread operator and modify checkIn/checkOut
                // https://daily-dev-tips.com/posts/javascript-overwrite-property-in-an-object/
                const receivedBookingDetails = {
                    ...paymentResponse.bookingDetails,
                    // convert date fields to moment
                    checkIn: moment(paymentResponse.bookingDetails.checkIn),
                    checkOut: moment(paymentResponse.bookingDetails.checkOut)
                };
                setBookingDetails(receivedBookingDetails);
                setSetupIntentClientSecret(paymentResponse.setupIntentClientSecret);
                setReturnUrl(generateReturnUrl(receivedBookingDetails));
            } else {
                const fetchError = await FetchError.createFetchError("requestPayment(): "
                    + "checkIn: " + bookingDetails?.checkIn
                    + ", checkOut: " + bookingDetails?.checkOut
                    + ", room: " + bookingDetails?.room,
                    fetchUrl,
                    response
                );
                Sentry.captureException(fetchError);
                setErrorMessage(messageFromFetchError(fetchError));
            }
        } else {
            Sentry.captureMessage(
                "requestPayment(): "
                + "checkIn: " + bookingDetails?.checkIn
                + ", checkOut: " + bookingDetails?.checkOut
                + ", room; " + bookingDetails?.room
            );
        }
    }

    const appearance: Appearance = {
        theme: 'stripe',
    };
    const options: stripeJs.StripeElementsOptions = {
        clientSecret: setupIntentClientSecret,
        appearance,
    };

    const onDatesChange = async (startDate: moment.Moment, endDate: moment.Moment, room: Room) => {
        // < because we only count the days before the day they check out
        // while (incrementedDate.getTime() < endDate.getTime()) {
        // }
        setBookingDetails(undefined);

        if (room === Room.COSY) {
            setSunsetCalendarVersion(sunsetCalendarVersion + 1);
        } else if (room === Room.SUNSET) {
            setCosyCalendarVersion(cosyCalendarVersion + 1);
        } else {
            Sentry.captureMessage("Unknown room " + room);
        }
        await createBooking(startDate, endDate, guest, room, bookingDetails?.numberOfGuests);
    };

    const onGuestChangedCallback = async (guest: Guest) => {
        setGuest(guest);
        if (!guest.confirmed) {
            // the client secret is potentially updated
            setSetupIntentClientSecret(undefined);
        }
    }

    const onContinueClicked = async (guest: Guest) => {
        setGuest(guest);
        requestPayment(guest);
    }

    const onNumberOfGuestsChanged = (numberOfGuests: number) => {
        createBooking(bookingDetails?.checkIn, bookingDetails?.checkOut,
            guest, bookingDetails?.room, numberOfGuests);
    }

    const paymentProcessingNotification = (isProcessing: boolean) => {
        setIsPaymentProcessing(isProcessing);
    }

    const showTermsClickedNotification = () => {
        setIsTermShowing(true);
    }

    const generateReturnUrl = (bookingDetails: BookingDetails): string => {
        return window.location.protocol + '//' + window.location.host + '/bookThankYou?'
            + "numberOfGuests=" + bookingDetails.numberOfGuests
            + "&room=" + formatRoom(bookingDetails.room)
            + "&checkIn=" + bookingDetails.checkIn.format("LL")
            + "&checkOut=" + bookingDetails.checkOut.format("LL")
            + "&price=" + formatPrice(bookingDetails.totalPrice);
    }

    const toIsoString = (day: moment.Moment): string => {
        return day.format("YYYY-MM-DD");
    }

    const calendarLoadError = async (error: Error) => {
        setErrorMessage(await messageFromError(error));
    }

    return (
        <Content>
            <ContainerCalendar>
                <BoxCalendar>
                    <BoxTitle>Cosyroom</BoxTitle>
                    <Calendar room={Room.COSY}
                              calendarVersion={cosyCalendarVersion} onDatesChange={onDatesChange} useLoader={true}
                              loadError={calendarLoadError}
                    />
                </BoxCalendar>
                <BoxCalendar>
                    <BoxTitle>Sunsetroom</BoxTitle>
                    <Calendar room={Room.SUNSET}
                              calendarVersion={sunsetCalendarVersion} onDatesChange={onDatesChange}
                              useLoader={true}/>
                </BoxCalendar>
            </ContainerCalendar>
            <BookingContainer>
                <div hidden={!bookingDetails}>
                    {bookingDetails && (
                        <BookingDetailsBox bookingDetails={bookingDetails}
                                           onNumberOfGuestsChanged={onNumberOfGuestsChanged}
                                           readOnly={guest?.confirmed === true}/>
                    )}
                    <GuestForm key={"GuestForm"} onContinueClicked={onContinueClicked}
                               onGuestChanged={onGuestChangedCallback}/>
                </div>
                {bookingLoading && (
                    <BoxBookingLoader>
                        <Oval
                            height={200}
                            width={200}
                            color="#4fa94d"
                            wrapperStyle={{"padding": "50px"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#4fa94d"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </BoxBookingLoader>
                )}
                {setupIntentClientSecret && returnUrl && guest?.confirmed === true && (
                    <Elements options={options} stripe={stripePromise}>
                        <PaymentForm returnUrl={returnUrl}
                                     paymentProcessingNotification={paymentProcessingNotification}
                                     showTermsClickedNotification={showTermsClickedNotification}
                        />
                    </Elements>
                )}
                {!setupIntentClientSecret && guest?.confirmed === true && !errorMessage && (
                    <BoxBookingLoader>
                        <Oval
                            height={200}
                            width={200}
                            color="#4fa94d"
                            wrapperStyle={{"padding": "50px"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#4fa94d"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </BoxBookingLoader>
                )}
            </BookingContainer>
            {isPaymentProcessing && (<PaymentLoaderContainer>
                <PaymentLoader>
                    <Oval
                        height={200}
                        width={200}
                        color="#4fa94d"
                        wrapperStyle={{"padding": "50px"}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                    />
                </PaymentLoader>
            </PaymentLoaderContainer>)}
            {isTermsShowing && (<OutsideClickHandler onOutsideClick={() => setIsTermShowing(false)}><TermsOverlay>
                    <Terms/>
                    <TermsCloseButton onClick={() => setIsTermShowing(false)}>Close</TermsCloseButton>
                </TermsOverlay>
                </OutsideClickHandler>
            )}
            <ErrorMessage hidden={!errorMessage}>{errorMessage}</ErrorMessage>
        </Content>
    );
};

export default Book;
