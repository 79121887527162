import {Content} from "../GlobalStyles";
import {DescriptionContainer, DescriptionText} from "./RoomStyles";
import React from "react";
import {NavLink} from "react-router-dom";

const Privacy = () => {
    return (
        <Content>
            <h1>Privacy Policy Cosyrooms</h1>
            <DescriptionContainer>
                <DescriptionText>
                    <p>Last updated: 25 March 2023</p>

                    This privacy policy governs your use of the software application "Cosyrooms" (“Application”) that
                    was created by Cosyrooms.

                    <h2>User Provided Information</h2>
                    There are no user provided information stored on servers of Cosyrooms. User and payment related
                    information for booking purpose is stored at our payment partner's servers.

                    <h2>Automatically Collected Information</h2>
                    The Application may collect certain information automatically, including, but not limited to, the
                    type of device you use, your devices unique device ID, the IP address of your
                    device, your operating system, the type of Internet browsers you use, and information
                    about the way you use the Application.

                    <h2>Does the Application collect precise real time location information of the device?</h2>
                    This Application does not collect precise information about the location of your device.

                    <h2>Do third parties see and/or have access to information obtained by the Application?</h2>
                    Only aggregated, anonymized data is periodically transmitted to external services to help us improve
                    the Application and our service. We will share your information with third parties only in the ways
                    that are described in this privacy statement.

                    We may disclose user provided and automatically collected information:
                    - as required by law, such as to comply with a subpoena, or similar legal process;
                    - when we believe in good faith that disclosure is necessary to protect our rights, protect your
                    safety or the safety of others, investigate fraud, or respond to a government request;
                    - with our trusted services providers who work on our behalf, do not have an independent use of the
                    information we disclose to them, and have agreed to adhere to the rules set forth in this privacy
                    statement.
                    - if Cosyrooms is involved in a merger, acquisition, or sale of all or a portion of its assets

                    <h2>What are my opt-out rights?</h2>
                    You can stop all collection of information by the Application easily by stop using the
                    Application.

                    <h2>Data Retention Policy</h2>
                    We will retain automatically collected information aggregated.

                    <h2>Children</h2>
                    We do not use the Application to knowingly solicit data from or market to children under the age of
                    13. If a parent or guardian becomes aware that his or her child has provided us with information
                    without their consent, he or she should contact us on the email address below. We will delete such
                    information from our files within a reasonable time.

                    <h2>Security</h2>
                    We are concerned about safeguarding the confidentiality of your information. We provide physical,
                    electronic, and procedural safeguards to protect information we process and maintain. For example,
                    we limit access to this information to authorized employees and contractors who need to know that
                    information in order to operate, develop or improve our Application. Please be aware that, although
                    we endeavor to provide reasonable security for information we process and maintain, no security
                    system can prevent all potential security breaches.

                    <h2>Changes</h2>
                    This Privacy Policy may be updated from time to time for any reason. You are advised to consult this
                    Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.

                    <h2>Your Consent</h2>
                    By using the Application, you are consenting to our processing of your information as set forth in
                    this Privacy Policy now and as amended by us. "Processing,” means using or touching information in
                    any way, including, but not limited to, collecting, storing, deleting, using, combining and
                    disclosing information.

                    <h2>Contact us</h2>
                    If you have any questions regarding privacy while using the Application, or have questions about our
                    practices, please contact us via <NavLink to={"/contact"}>contact form</NavLink>.
                </DescriptionText>
            </DescriptionContainer>
        </Content>
    )
};

export default Privacy;
