import React, {FormEvent, useState} from 'react';
import {FormError, Input, Label} from "./ContactStyles";
import {useForm} from "react-hook-form";
import {BoxBooking, BoxTitle} from "./BookStyles";
import {FieldError} from "react-hook-form/dist/types/errors";
import * as Sentry from "@sentry/react";

export class Guest {
    readonly givenName: string;
    readonly familyName: string;
    readonly email?: string;
    readonly phone?: string;
    readonly confirmed?: boolean = false;

    constructor(givenName: string, familyName: string,
                email: string | undefined = undefined, phone: string | undefined = undefined,
                confirmed: boolean | undefined = false) {
        this.givenName = givenName;
        this.familyName = familyName;
        this.email = email;
        this.phone = phone;
        this.confirmed = confirmed
    }
}

export class Props {
    onGuestChanged: (guest: Guest) => void
    onContinueClicked: (guest: Guest) => void

    constructor(guest: Guest | undefined, onCustomerChanged: (guest: Guest) => void,
                onContinueClicked: (guest: Guest) => void) {
        this.onGuestChanged = onCustomerChanged;
        this.onContinueClicked = onContinueClicked;
    }
}

const GuestForm = (props: Props) => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}} = useForm<Guest>();
    const [guest, setGuest] = useState<Guest | undefined>(undefined);

    const onSubmit = async (event: FormEvent) => {
        event.preventDefault();
        // trim whitespaces on email before validation
        const email = getValues("email");
        setValue("email", email?.trim());
        // note the () to call handleSubmit()
        await handleSubmit(async (guest: Guest) => {
            const confirmedGuest = {
                ...guest,
                confirmed: true
            };
            setGuest(confirmedGuest);
            props.onContinueClicked(confirmedGuest)
        })();
    }
    const onEditClicked = () => {
        if (guest !== undefined) {
            const unconfirmedGuest = {
                ...guest,
                confirmed: false
            };
            setGuest(unconfirmedGuest);
            props.onGuestChanged(unconfirmedGuest);
        } else {
            // should not happen
            setGuest(undefined);
            Sentry.captureMessage("onEditClicked(): guest undefined");
        }
    }
    const getEmailError = (fieldError: FieldError): string => {
        if (fieldError.type === "pattern") {
            return "Wrong format";
        } else {
            return "This field is required";
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <BoxBooking>
                <BoxTitle>Guest Information</BoxTitle>
                {guest?.confirmed === true && (
                    <div>
                        <table>
                            <tbody>
                            <tr>
                                <td>Given name:</td>
                                <td>{guest?.givenName}</td>
                            </tr>
                            <tr>
                                <td>Family name:</td>
                                <td>{guest?.familyName}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{guest?.email}</td>
                            </tr>
                            <tr>
                                <td>Mobile:</td>
                                <td>{guest?.phone}</td>
                            </tr>
                            </tbody>
                        </table>
                        <button onClick={onEditClicked}>Edit</button>
                    </div>
                )}
                {guest?.confirmed !== true && (
                    <div>
                        <Label>
                            Given name:
                            <Input {...register("givenName", {required: true})} />
                            {errors.givenName && <FormError>This field is required</FormError>}
                        </Label>
                        <Label>
                            Family name:
                            <Input {...register("familyName", {required: true})} />
                            {errors.familyName && <FormError>This field is required</FormError>}
                        </Label>
                        <Label>
                            Email:
                            <Input {...register("email", {
                                required: true,
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                            })} />
                            {errors.email && <FormError>{getEmailError(errors.email)}</FormError>}
                        </Label>
                        <Label>
                            Mobile:
                            <Input {...register("phone", {required: false})} />
                            {errors.phone && <FormError>This field is required</FormError>}
                        </Label>
                            <button>Continue</button>
                        </div>
                    )}
                </BoxBooking>
            </form>
        )
    }
;

export default GuestForm;
