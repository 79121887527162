import React, {useEffect} from "react";
import {Content} from "../GlobalStyles";
import {DescriptionContainer, DescriptionText} from "./RoomStyles";
import {BoxBookingThankYou, BoxTitle} from "./BookStyles";
import {useLocation} from "react-router-dom";
import {API_TOKEN, BASE_URL} from "../Global";
import {fetchWithFetchException} from "../util/FetchWrapper";
import {FetchError} from "../util/FetchError";
import * as Sentry from "@sentry/react";

const BookThankYou = () => {
    const {search} = useLocation();
    const urlSearchParams = new URLSearchParams(search);

    useEffect(() => {
        createPayment()
    });

    const createPayment = async () => {
        if (urlSearchParams.has("setup_intent")) {
            let fetchUrl = `${BASE_URL}/checkout/payment/${urlSearchParams.get("setup_intent")}?${API_TOKEN}`;
            console.log("fetchUrl: " + fetchUrl);
            const response: Response = await fetchWithFetchException(fetchUrl, {
                    method: 'POST'
                }
            )
            if (!response.ok) {
                Sentry.captureException(await FetchError.createFetchError("create payment failed", fetchUrl, response));
            }
        }

        if (urlSearchParams.get("redirect_status") !== 'succeeded') {
            Sentry.captureMessage("payment not succeeded, redirect_status: "
                + urlSearchParams.get("redirect_status")
                + "createPayment():"
                + " setupIntent: " + urlSearchParams.get("setup_intent")
                + ", unexpected status " + urlSearchParams.get("redirect_status"));
        }
    }

    return (
        <Content>
            <h1>Thank you</h1>
            <DescriptionContainer>
                <DescriptionText>
                    <p>Thank you for your booking. We'll contact you soon by email to confirm it.</p>
                    <BoxBookingThankYou>
                        <BoxTitle>Reservation</BoxTitle>
                        <table>
                            <tbody>
                            <tr>
                                <td>Number of guests:</td>
                                <td>{urlSearchParams.get("numberOfGuests")}</td>
                            </tr>
                            <tr>
                                <td>Room:</td>
                                <td>{urlSearchParams.get("room")}</td>
                            </tr>
                            <tr>
                                <td>Check in:</td>
                                <td>{urlSearchParams.get("checkIn")}</td>
                            </tr>
                            <tr>
                                <td>Check out:</td>
                                <td>{urlSearchParams.get("checkOut")}</td>
                            </tr>
                            <tr>
                                <td>Price:</td>
                                <td>{urlSearchParams.get("price")}</td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td>State:</td>*/}
                            {/*    <td>{urlSearchParams.get("redirect_status") === 'succeeded' ? "Success" : "Failure"}</td>*/}
                            {/*</tr>*/}
                            </tbody>
                        </table>
                    </BoxBookingThankYou>
                </DescriptionText>
            </DescriptionContainer>
        </Content>
    );
};

export default BookThankYou;
