export class FetchError extends Error {
    readonly userMessage: string;

    constructor(msg: string, userMessage: string) {
        super(msg);
        this.userMessage = userMessage;

        // Set the prototype explicitly.
        // It's worth mentioning that Object.setPrototypeOf needs to be called immediately after any super(...) calls.
        // https://stackoverflow.com/questions/31626231/custom-error-class-in-typescript
        Object.setPrototypeOf(this, FetchError.prototype);
    }

    static async createFetchError(msg: string, input: RequestInfo, response: Response): Promise<FetchError> {
        const responseJson = await response.json();
        const responseMessage = responseJson.message ?? responseJson;
        return new FetchError(
            msg + ", url: " + input + ", code: " + response.status + ", msg: " + response.statusText,
            responseMessage
        );
    }
}
