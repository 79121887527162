import * as React from "react";
import {NavLink} from "react-router-dom";
import {FetchError} from "./FetchError";

export class ResponseError {
    readonly status: Number;
    readonly message: string;


    constructor(status: Number, message: string) {
        this.status = status;
        this.message = message;
    }
}

export const messageFromFetchError = (fetchError: FetchError): React.ReactElement<string> => {
    const message = fetchError.userMessage ?? fetchError.message;
    if (message !== undefined && message.length > 0) {
        return <div>An error occurred: {fetchError.userMessage}. Please try again or <NavLink to="/contact">contact
            us</NavLink>.
        </div>;
    } else {
        return <div>An error occurred, please try again or <NavLink to="/contact">contact us</NavLink>.</div>
    }
}

export const messageFromError = (error: Error): React.ReactElement<string> => {
    const message = error.message;
    if (message !== undefined && message.length > 0) {
        return <div>An error occurred: {message}. Please try again or <NavLink to="/contact">contact us</NavLink>.
        </div>;
    } else {
        return <div>An error occurred, please try again or <NavLink to="/contact">contact us</NavLink>.</div>
    }
}
