import React, {FormEvent, useState} from 'react';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {A, BoxBooking, BoxTitle, ContainerBookingButton, ErrorMessageDiv} from "./BookStyles";
import * as Sentry from "@sentry/react";

export class Props {
    returnUrl: string
    paymentProcessingNotification?: (isProcessing: boolean) => void;
    showTermsClickedNotification?: () => void;

    constructor(returnUrl: string,
                paymentProcessingNotification: (isProcessing: boolean) => void | undefined,
                showTermsClickedNotification: () => void | undefined
    ) {
        this.returnUrl = returnUrl;
        this.paymentProcessingNotification = paymentProcessingNotification;
        this.showTermsClickedNotification = showTermsClickedNotification;
    }
}

const PaymentForm = (props: Props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [agreedTerms, setAgreedTerms] = useState(false);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        props.paymentProcessingNotification?.(true);

        try {
            const {error} = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: props.returnUrl,
                },
            });

            if (error) {
                setErrorMessage(error.message);
                Sentry.captureException(error);
                // Sentry.captureMessage("Payment error: " + error.message
                //     + "\n" + JSON.stringify(error));
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }

            props.paymentProcessingNotification?.(false);
        } catch (e: any) {
            props.paymentProcessingNotification?.(false);
        }
    };
    const showTermsClickedNotification = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        props.showTermsClickedNotification?.();
    }

    return (
        <form onSubmit={onSubmit}>
            <BoxBooking>
                <BoxTitle>Payment</BoxTitle>
                <PaymentElement/>
                <div>
                    <input type="checkbox" id="agree" checked={agreedTerms}
                           onChange={() => setAgreedTerms(!agreedTerms)}/>
                    <label htmlFor="agree">
                        I agree to <A onClick={showTermsClickedNotification}>Terms of use</A>
                    </label>
                </div>
            </BoxBooking>
            {/* Show error message to your customers */}
            {errorMessage && <ErrorMessageDiv>{errorMessage}</ErrorMessageDiv>}
            <ContainerBookingButton>
                <button disabled={!stripe || !agreedTerms}>Book</button>
            </ContainerBookingButton>
        </form>
    )
};

export default PaymentForm;
