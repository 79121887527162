import React from "react";
import {Content} from "../GlobalStyles";
import {Checkin, CheckinImg, CheckinPortraitImg} from "./CheckinStyles";
import {useSearchParams} from "react-router-dom";

const CosyCheckin = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    let codeText: string;
    if (code) {
        codeText = "Key safe code: " + code;
    } else {
        codeText = "In case we don't meet in person, I'll send you the code with a chat message.";
    }
    return (
        <Content>
            <h1>Check-in instructions Cosyroom</h1>
            <p>56 Novara Crescent, Como NSW 2226, Australia - <a
                href="https://www.google.ch/maps/place/56+Novara+Cres,+Como+NSW+2226/">Show map </a></p>
            <Checkin>
                <h2>Step 1:</h2>
                <CheckinImg alt="Check in Step 1" src={require('../images/cosy/checkIn1.jpg')}/>
                <p>Parking is in front of the house alongside the guard rail.</p>
                <h2>Step 2:</h2>
                <CheckinImg alt="Check in Step 2" src={require('../images/PXL_20240128_084529273.jpg')}/>
                <p>Enter the code on the keypad. The LED below the keypad lights up green if it was correct.<br/>
                    {codeText}</p>
                <h2>Step 3:</h2>
                <CheckinImg alt="Check in Step 3" src={require('../images/shoes.jpg')}/>
                <p>Please take off the shoes when you enter the house.</p>
                <h2>Step 4:</h2>
                <CheckinPortraitImg alt="Check in Step 4" src={require('../images/cosy/checkIn3.jpg')}/>
                <p>Your room is the first brown door on the right with the name "Cosyroom".</p>
            </Checkin>
        </Content>
    );
};

export default CosyCheckin;
