import {NavLink as Link} from "react-router-dom";
import styled from "styled-components";

export const StyledBurgerMenu = styled.div`
  // Position and sizing of burger button
  .bm-burger-button {
    position: absolute;
    margin: 6px;
    width: 36px;
    height: 30px;
  }

  // Color/shape of burger icon bars
  .bm-burger-bars {
    background: #373a47;
  }

  // Position and sizing of clickable cross button
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  // Color/shape of close button cross
  .bm-cross {
    background: #bdc3c7;
  }

  // General sidebar styles
  .bm-menu {
    background: #dbedfc;
    font-size: 1.15em;
  }

  // Morph shape necessary with bubble or elastic
  .bm-morph-shape {
    fill: #373a47;
  }

  // Wrapper for item list
  .bm-item-list {
    color: #b8b7ad;
  }

  // Individual item
  .bm-item {
    color: #808080;
    align-items: center;
    text-decoration: none;
    padding: 12px;
    cursor: pointer;

    &.active {
      color: #4d4dff;
    }
  }

  // Styling of overlay
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`;
export const Nav = styled.nav`
  background: #dbedfc;
  height: 42px;
  z-index: 12;
`;

export const NavLink = styled(Link)`
  color: #808080;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  cursor: pointer;
  &.active {
    color: #4d4dff;
  }
`;
